import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { CircularProgress, Grid, Typography } from "@mui/material";
import { experimentalStyled as styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import * as React from "react";
import { useSnapshot } from "valtio";
import { widState } from "../../../store/workdriveState";
import * as ApiCall from "../../../../../API/ApiCalling";
import useFileNameLength from "../CustomHooks/useFileNameLength";
import ModalFile from "../Modals/ModalFile";
import "./../css/project_folder.css";
const CustomImage = styled("img")(({ theme }) => ({
  height: "145px",
  minWidth: "100%",
  objectFit: "fill",
  backgroundSize: "cover",
  "z-index": -1,
}));

// const BoxThumb = styled('Box')(({ theme }) => ({
//   minHeight: '150px',
//   minWidth: '100%',
//   display: 'flex',
//   justifyContent: 'center',
//   alignItems: 'center',
// }))

const BoxThumb = styled("Box")(({ theme }) => ({
  minHeight: "75%",
  minWidth: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const useStyles = makeStyles({
  fileroot: {
    border: "1px solid #BABEC2",
    borderRadius: "5px",
    minHeight: "180px",
    "&:hover": {
      boxShadow: "0 0 1px 1px #E0E0E0",
      transitionDuration: "0.3s",
    },
    "&:focus": {
      border: "2px solid #28B294",
    },
  },
  item: {
    textAlign: "left !important",
  },

  image: {
    width: "200px",
    height: "150px",
  },
  details: {
    margin: "0 auto",
    paddingTop: "5px",
  },
});

export default function FileImageCard({
  file,
  settingId,
  connname,
  orgid,
  apikey,
  datacenterurl,
  settingUrl,
}) {
  const [open, setOpen] = React.useState(false);
  const snap = useSnapshot(widState);
  const [profileImage, setProfileImage] = React.useState();
  const [thumbnailImage, setThumbnailUrl] = React.useState();
  const componentRef = React.useRef();

  const [len, setLen] = React.useState(16);
  let { nameLen } = useFileNameLength(file?.name);
  React.useEffect(() => {
    setLen(nameLen);
  }, [nameLen]);

  React.useEffect(() => {
    if (!file) {
      setThumbnailUrl((prev) => "error");
      return;
    }
    const fetchThumbnail = async () => {
      try {
        if (
          widState?.settingData?.[settingId]?.thumbnailUrls?.hasOwnProperty(
            file?.id
          )
        ) {
          setThumbnailUrl(
            (prev) =>
              widState.settingData?.[settingId]?.thumbnailUrls?.[file?.id]
          );
        } else {
          const response = await ApiCall.getThumbnailData(
            file,
            connname,
            orgid,
            apikey,
            datacenterurl,
            settingUrl
          );

          if (response?.data?.size < 70) {
            setThumbnailUrl((prev) => "error");
            return;
          }

          
          var blodData = new Blob([response.data]);
          var urlData = URL.createObjectURL(blodData);
          widState.setThumbNail(settingId, file, urlData);
          setThumbnailUrl((prev) => urlData);
        }
      } catch (error) {
        setThumbnailUrl("error");
      }
    };
    fetchThumbnail();
  }, [file?.id]);

  const getUserImage = async (file) => {
    let res = await ApiCall.getImageResponse(
      file,
      connname,
      orgid,
      apikey,
      datacenterurl,
      settingUrl
    );

    var file = new Blob([res.data]);
    var fileURL = URL.createObjectURL(file);

    setProfileImage(fileURL);
  };

  // React.useEffect(() => {
  //   async function fetchData() {
  //     let resData = await ApiCall.getThumbnailData(
  //       file,
  //       connname,
  //       orgid,
  //       apikey,
  //       datacenterurl,
  //       settingUrl
  //     );
  //     var blodData = new Blob([resData.data]);
  //     var urlData = URL.createObjectURL(blodData);
  //     // ;
  //     setThumbnailUrl(urlData);
  //   }
  //   fetchData();
  // }, [file?.id]);

  const handleDisplay = (thumbnailImage) => {
    if (!thumbnailImage) {
      return (
        <BoxThumb>
          <CircularProgress color="secondary" />
        </BoxThumb>
      );
    } else if (thumbnailImage === "error") {
      return (
        <BoxThumb sx={{ backgroundColor: "#F8F8F8" }}>
          <DescriptionOutlinedIcon sx={{ fontSize: "80px" }} />
        </BoxThumb>
      );
    } else if (thumbnailImage) {
      return <CustomImage src={thumbnailImage} />;
    }
    // return (
    //   <BoxThumb>
    //     <CircularProgress />
    //   </BoxThumb>
    // );
  };

  const handleClickOpen = (file) => {
    window.open(
      `https://workdrive.zoho.${
        widState.dataCenterUrl.split("https://www.zohoapis.")?.[1]
      }/file/${file?.id}`,
      "_blank",
      "noreferrer"
    );
    // setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const classes = useStyles();

  return (
    <Grid container className={classes.fileroot}>
      <Grid item container tabIndex="1" onClick={() => handleClickOpen(file)}>
        {handleDisplay(thumbnailImage)}
        <br />
        <Grid className={classes.details}>
          {len && (
            <Typography variant="h6">
              {`${file?.name?.substr(0, len)}...`}
            </Typography>
          )}
        </Grid>
      </Grid>
      {/* <div>{JSON.stringify(profileImage)}</div> */}
      {/* <ModalFile
        open={open}
        setOpen={setOpen}
        profileImage={profileImage}
        file={file}
        datacenterurl={datacenterurl}
        settingId={settingId}
        connname={connname}
        orgid={orgid}
        apikey={apikey}
        settingUrl={settingUrl}
      /> */}
    </Grid>
  );
}

// <Grid>
//   <Grid
//     tabIndex="1"
//     className={classes.fileroot}
//     onClick={() => handleClickOpen(file)}
//   >
//     {handleDisplay(thumbnailImage)}
//     <br />
//   </Grid>
//   <ModalFile
//     open={open}
//     setOpen={setOpen}
//     profileImage={profileImage}
//     file={file}
//     datacenterurl={datacenterurl}
//     settingId={settingId}
//   />
// </Grid>;
